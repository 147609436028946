<template>
  <div
    style="margin-top: 0px; z-index: 2"
    @click="removeLists"
  >
    <div>
      <div class="filtering">

        <div class="search-toggle">

          <p>{{ $t('SearchBy') }}</p>
          <div>
            <p
              :class="{ active2: !toggleSarch }"
              @click="showMaterialVariantName()"
            >
              {{ $t('MaterialVariant') }}
            </p>
            <p
              :class="{ active2: toggleSarch }"
              @click="showMaterialName()"
            >
              {{ $t('MaterialName') }}
            </p>
          </div>
        </div>
        <div v-if="show == true">
          <div>
            <div class="black">
              <b-icon-search
                v-if="materialVariantName == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="materialVariantName != '' && !noSearch"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="materialVariantName = ''"
              />
              <input
                v-model="materialVariantName"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('MaterialVariant')"
                :style="getMaterialVariantLocationNameLike.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="getMaterialVariantLocationNameLike.length > 0 && noSearch"
              ref="scrollContainer"
              class="dropdrop"
              style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              width: 229px;
              box-shadow: none;"
              :style="getMaterialVariantLocationNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getMaterialVariantLocationNameLike"
                ref="options2"
                :key="index"
                :class="{ 'is-active': index === arrowCounter }"
                style="cursor: pointer"
                class="search-item"
                @click="searchByVariantName(result.locationName ); noSearch = false"
              >
                <p style="margin: 0">
                  {{ result.locationName }}
                </p>
              </div>
              <div
                v-if="getMaterialVariantLocationNameLike.length == 0"
                class="search-item"
              >
                <p>{{ $t('NoResult') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="show == false">
          <div class="black">
            <b-icon-search
              v-if="materialName == ''"
              class="search2"
              style="position: absolute; left: 5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="materialName != '' && !noSearchTwo"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="materialName = ''"
            />
            <input
              v-model="materialName"
              type="text"
              name="search-box"
              :style="getMaterialLocationMaterialNameLike.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
              :placeholder="$t('MaterialName')"
              @keyup="inputChanged2"
              @keydown.down="onArrowDown2"
              @keydown.up="onArrowUp2"
            >
          </div>
          <div
            v-if="getMaterialLocationMaterialNameLike.length > 0 && noSearchTwo"
            ref="scrollContainer2"
            class="dropdrop"
            style="
            display: inline-block;
            overflow: auto;
            position: absolute;
            background: white;
            width: 229px;
            box-shadow: none;
            "
            :style="getMaterialLocationMaterialNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getMaterialLocationMaterialNameLike"
              :key="index"
              ref="options2"
              style="cursor: pointer"
              :class="{ 'is-active': index === arrowCounter2 }"
              class="search-item"
              @click="searchByName(result.locationName); noSearchTwo = false"
            >
              <p style="margin: 0">
                {{ result.locationName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%;padding-top: 16px; margin-top: 15px; height: 84vh; overflow-y: scroll;">
        <table
          ref="exportable_table"
          class="team_table"
        >
          <thead style="position: sticky; top: -18px;">
            <tr>
              <th>
                {{ $t('MaterialVariantsName') }}
              </th>
              <th>
                {{ $t('MaterialName') }}
              </th>
              <th>
                {{ $t('Location Name') }}
              </th>
              <th>
                {{ $t('LocationType') }}
              </th>
              <th>
                {{ $t('CurrentAmount') }}
              </th>
              <th>
                {{ $t('Notes') }}
              </th>

              <!-- <th
                v-if="getLoggedInUser.role != 'TeamLeader' && getLoggedInUser.role != 'SalesPerson'"
                style="padding: 10px;text-align: left; color:#262E6C !important; font-size: 15px;"
              >
                {{ $t('Edit') }}
              </th>
            </tr> -->
            </tr></thead>
          <tbody v-if="getMaterialVariantlocation.length > 0">
            <tr
              v-for="(order,index) in getMaterialVariantlocation"
              :key="index"
            >
              <td>
                {{ order.materialVariantName }}
              </td>
              <td>
                {{ order.materialName }}
              </td>
              <td>
                {{ order.locationName }}
              </td>
              <td>
                {{ order.locationType }}
              </td>
              <td>
                {{ order.currentAmount }}
              </td>
              <td>
                {{ order.notes }}
              </td>
              <!-- <td
                v-if="getLoggedInUser.role != 'TeamLeader' && getLoggedInUser.role != 'SalesPerson'"
                style="padding: 9px; text-align: left; cursor: pointer;font-weight: bold; color: black;"
                @click="editC(order)"
              >
                <p
                  v-b-modal.modal-edit-color
                  class="p-0 m-0 ml-50"
                >
                  <b-icon-pencil />
                </p>
              </td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                colspan="5"
                style="padding: 9px; text-align: left;"
              >
                {{ $t('No_Colors_are_shown') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <b-pagination
          v-if="getTotalItemsForMaterials > 15"
          v-model="page"
          :total-rows="getTotalItemsForMaterials"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
    <!-- <AddNewColorVue @addNewColor="addCol" />
    <EditColorModal
      :color-data="color"
      @editColor="editCol"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import AddNewColorVue from '@/modals/AddNewColor.vue';
// import EditColorModal from '../modals/EditColorModal.vue'

export default {
  components: {
    // AddNewColorVue,
    // EditColorModal,
  },
  data() {
    return {
      toggleSarch: false,
      show: true,
      noSearch: false,
      noSearchTwo: false,
      searchColor: '',
      page: 1,
      arrowCounter: 0,
      enterPressed: false,
      colorName: '',
      materialVariantName: '',
      materialName: '',
      pageSize: 15,
      color: {
        colorDataId: '',
        colorName: '',
        hashColor: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getMaterialVariantlocation', 'getMaterialVariantLocationNameLike', 'getMaterialLocationMaterialNameLike',
      'getColorsData', 'getLoggedInUser', 'getCurrentPageColors', 'getTotalItemsForMaterials', 'getColorNames']),
  },
  watch: {
    page(value) {
      this.materialVariant_location_Pagination({
        materialVariantId: null, materialVariantName: null, materialName: null, pageNumber: value, pageSize: this.pageSize,
      })
    },
    materialVariantName(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
        // this.fshijCN();
          this.materialVariant_location_Pagination({
            materialVariantId: null,
            materialVariantName: null,
            materialName: null,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.materialVariant_location_nameLike(value);
          this.noSearch = true
        }
      }, 500)
    },
    materialName(value) {
      if (value == '') {
        // this.fshijCN();
        this.materialVariant_location_Pagination({
          materialVariantId: null,
          materialVariantName: null,
          materialName: null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return
      } else {
        this.material_location_materialNameLike(value);
        this.noSearchTwo = true
      }
    },
  },
  mounted() {
    // this.checkIfArticleNumWasNull()
    // console.log('test ColorTable', this.fetch())
    this.fetch()
  },
  methods: {
    ...mapActions([
      'changeLoadingtoTrue', 'materialVariant_location_nameLike', 'material_location_materialNameLike',
      'addColor', 'loadColors', 'colorNameLike', 'resetColorNames', 'editColor', 'materialVariant_location_Pagination']),
    async addCol(obj) {
      await this.addColor({
        object: obj,
        successCallback: () => {
          this.loadColors({ colorName: '', pageNumber: 1, pageSize: this.pageSize });
          this.page = 1
        },
      });
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false)

        await this.materialVariant_location_Pagination({
          materialVariantId: null, materialVariantName: null, materialName: null, pageNumber: this.page, pageSize: this.pageSize,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    removeLists() {
      this.noSearch = false;
      this.resetColorNames();
    },
    editC(item) {
      this.color.colorDataId = item.id;
      this.color.colorName = item.colorName;
      this.color.hashColor = item.hashColor
    },
    // checkIfArticleNumWasNull() {
    //   // eslint-disable-next-line no-unused-vars
    //   for (let i = 0; i < this.getOrderTrackItems.length; i++) {
    //     if (this.getOrderTrackItems[0].orderItemId != this.getOrderTrackItems[i].orderItemId) {
    //       // eslint-disable-next-line no-const-assign
    //       this.wasOrNot = true
    //     }
    //   }
    //   return this.wasOrNot
    // },

    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getMaterialVariantLocationNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getColorsData = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByVariantName(this.getMaterialVariantLocationNameLike.locationName[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    onArrowDown2(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getMaterialLocationMaterialNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling2();
      }
    },

    onArrowUp2(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling2()
      }
    },


    inputChanged2(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getColorsData = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByName(this.getMaterialLocationMaterialNameLike[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling2() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    showMaterialVariantName() {
      this.toggleSarch = false;
      this.materialVariantName = '';
      this.show = true
      this.materialVariant_location_Pagination({
        materialVariantId: null,
        materialVariantName: null,
        materialName: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    showMaterialName() {
      this.toggleSarch = true;
      this.materialName = '';
      this.show = false;
      this.materialVariant_location_Pagination({
        materialVariantId: null,
        materialVariantName: null,
        materialName: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },

    async searchByVariantName(value) {
      this.materialVariantName = value
      this.noSearch = false
      this.materialVariant_location_Pagination({
        materialVariantId: null,
        materialVariantName: this.materialVariantName,
        materialName: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      // this.checkIfArticleNumWasNull()
    },
    async searchByName(value) {
      this.materialName = value
      this.noSearchTwo = false
      this.materialVariant_location_Pagination({
        materialVariantId: null,
        materialVariantName: null,
        materialName: this.materialName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
      // this.checkIfArticleNumWasNull()
    },
  },
};
</script>

<style scoped lang="scss">
.dropdrop::-webkit-scrollbar {
  display: none;
}

.team_table td {
  padding: 16px 9px !important;
}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

table:hover {
  cursor: pointer;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}
.active2 {
  font-weight: bold;
  color: $base-color;
}
.active2{
  color: $base-color;
  font-weight: bold;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
